import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";

import tricouImage from "./images/tricou.png";
import papiImage from "./images/papi.png";
import sugusImage from "./images/sugus.png";
import cutieImage from "./images/cutie.png";

import circleImage from "./images/circle.png";

import { useLocation, useNavigate } from "react-router-dom";

function WheelGame() {

  const navigate = useNavigate();
  const locationHook = useLocation();

  useEffect(() => {
    const token = localStorage.getItem("register_token");

    console.log("token - ", token);
    if (token === null) {
      navigate(`/login`);
    }
  }, []);

  const [isEffectRan, setIsEffectRan] = useState(false);
  const [dataIsLoading, setDataIsLoading] = useState(false);

  const [isFinalMessageShow, setIsFinalMessageShow] = useState(null);
  const [isFinalMessageShowActive, setIsFinalMessageShowActive] =
    useState(false);
  const [showFinalWindow, setShowFinalWindow] = useState(false);
  const [showFinalWindowActive, setShowFinalWindowActive] = useState(false);

  const [formPrizeValues, setFormPrizeValues] = useState({
    email: "",
    prize: null,
  });


  const wheelContainerRef = useRef(null);
  const rendererRef = useRef(null);
  const segmentsGroupRef = useRef(null);
  const spinWheelRef = useRef(null);
  const resetWheelRef = useRef(null);

  const buttonRef = useRef();
  const setupWheel = () => {
    let loadedImgs = 1;
    const wheelContainer = wheelContainerRef.current;
    const sceneCircle = new THREE.Scene();

    const camera = new THREE.PerspectiveCamera(45, 1 / 1, 1, 2000);
    camera.position.z = 600;

    const renderer = new THREE.WebGLRenderer({
      alpha: true,
      antialias: true,
      powerPreference: "high-performance",
    });
    if (wheelContainer.offsetWidth > wheelContainer.offsetHeight) {
      renderer.setSize(
        wheelContainer.offsetHeight,
        wheelContainer.offsetHeight
      );
    } else {
      renderer.setSize(wheelContainer.offsetWidth, wheelContainer.offsetHeight);
    }
    wheelContainer.appendChild(renderer.domElement);
    rendererRef.current = renderer;

    const numSegments = 4;
    const segmentAngle = (2 * Math.PI) / numSegments;

    const segmentHeight = 10;
    const radius = 220;
    const theta = (2 * Math.PI) / numSegments;

    const segmentShape = new THREE.Shape();
    const x_a = 0;
    const y_a = 0;
    const x_c = radius * Math.cos(theta);
    const y_c = radius * Math.sin(theta);
    const x_d = radius * Math.cos(2 * theta);
    const y_d = radius * Math.sin(2 * theta);

    segmentShape.moveTo(x_a + 1.0, y_a);
    segmentShape.lineTo(x_a, y_a + 2.0);
    segmentShape.lineTo(x_c, y_c + 2.0);

    const arcRadius = radius;
    const arcStartAngle = theta;
    const arcEndAngle = 2 * theta;

    const theta_mid = (arcStartAngle + arcEndAngle) / 2;

    // Coordonatele punctului median de pe arc
    const x_mid = arcRadius * Math.cos(theta_mid);
    const y_mid = arcRadius * Math.sin(theta_mid);
    console.log(x_mid, y_mid);

    segmentShape.absarc(0, 0, arcRadius, arcStartAngle, arcEndAngle, false);

    segmentShape.lineTo(x_a, y_a + 1.0);

    const extrudeSettings = {
      steps: 1,
      depth: segmentHeight,
      bevelEnabled: false,
    };

    const segmentGeometry = new THREE.ExtrudeGeometry(
      segmentShape,
      extrudeSettings
    );
    const segmentsGroup = new THREE.Group();

    const imageUrls = [tricouImage, sugusImage, papiImage, cutieImage];

    for (let i = 0; i < numSegments; i++) {
      const segmentMaterial = new THREE.MeshBasicMaterial({
        color: i % 2 ? `#69BC45` : `#3594D1`,
        transparent: true,
        wireframe: false,
      });
      const segment = new THREE.Mesh(segmentGeometry, segmentMaterial);
      segment.position.set(0, 0, 25);
      const singleSegmentGroup = new THREE.Group();
      singleSegmentGroup.add(segment);

      const midAngle = i * segmentAngle + segmentAngle / 2;
      console.log(midAngle);
      const distanceFromCenter = radius * 0.6;

      const loader = new THREE.TextureLoader();

      const imageUrl = imageUrls[i % imageUrls.length];
      loader.load(imageUrl, function (texture) {
        const aspectRatio = texture.image.width / texture.image.height;

        const baseWidth = 125;
        const scaleFactor = 4 / numSegments;
        const width = baseWidth * scaleFactor;
        const height = width / aspectRatio;

        const imageGeometry = new THREE.PlaneGeometry(width, height);
        const material = new THREE.MeshBasicMaterial({
          map: texture,
          transparent: true,
        });

        const cube = new THREE.Mesh(imageGeometry, material);
        singleSegmentGroup.add(cube);

        cube.position.x = x_mid/2 +5;
        cube.position.y = radius/2 -25;
        cube.position.z = 100;
        cube.rotation.z =  2* Math.PI /numSegments;

        console.log(
          `Segment ${i}: Position (${cube.position.x}, ${cube.position.y}), Size: ${width}x${height}, center ${x_mid} ${y_mid}`
        );
      });

      singleSegmentGroup.add(segment);

      segmentsGroup.add(singleSegmentGroup);
      singleSegmentGroup.rotation.z = i * ((2 * Math.PI) / numSegments);
    }

    renderer.localClippingEnabled = true;

    const ringGeometry = new THREE.RingGeometry(200, 190, 128, 5);

    const ringMaterial = new THREE.MeshBasicMaterial({
      color: 0x2c8281,
      side: THREE.DoubleSide,
      transparent: false,
      opacity: 1,
      linewidth: 10,
    });

    const ringMesh = new THREE.Mesh(ringGeometry, ringMaterial);

    ringMesh.position.set(0, 0, 100);

    sceneCircle.add(ringMesh);

    const triangleGeometry = new THREE.BufferGeometry();
    const vertices = new Float32Array([0, -30, 0, -18, 15, 0, 18, 15, 0]);

    triangleGeometry.setAttribute(
      "position",
      new THREE.BufferAttribute(vertices, 3)
    );

    // Creează material pentru triunghi
    const triangleMaterial = new THREE.MeshBasicMaterial({
      color: 0xEC1E24,
      side: THREE.DoubleSide,
      wireframe: false,
    });

    const triangleMesh = new THREE.Mesh(triangleGeometry, triangleMaterial);

    triangleMesh.position.set(0, 190, 101);
    sceneCircle.add(triangleMesh);

    sceneCircle.add(segmentsGroup);
    segmentsGroupRef.current = segmentsGroup;

    // const centerCircleGeometry = new THREE.CircleGeometry(10, 128);
    // const centerCircleMaterial = new THREE.MeshBasicMaterial({
    //   color: 0x50a6a5,
    // });
    // const centerCircleMesh = new THREE.Mesh(
    //   centerCircleGeometry,
    //   centerCircleMaterial
    // );

    // centerCircleMesh.position.set(0, 0, 105);
    // sceneCircle.add(centerCircleMesh);

    const showFinalMessage = (prizeIndex) => {
      setIsFinalMessageShow(prizeIndex);
    };

    spinWheelRef.current = (prizeIndex) => {
      let rotationSpeed = 0.1;

      // const rotationTarget = -10 * Math.PI - (prizeIndex / numSegments) * 2 * Math.PI;
      const getRandomOffset = (min, max) => {
        return Math.random() * (max - min) + min;
      };

      const rotationPerSegment = (2 * Math.PI) / numSegments;
      const currentRotation = segmentsGroup.rotation.z;

      const randomOffset = getRandomOffset(0.1, rotationPerSegment - 0.1);

      const rotationTarget =
        currentRotation -
        (prizeIndex * rotationPerSegment + 10 * Math.PI) +
        randomOffset;

      const easeOutCubic = (t) => {
        return 1 - Math.pow(1 - t, 3);
      };

      const cubicBezier = (t, p0, p1, p2, p3) => {
        const u = 1 - t;
        return (
          Math.pow(u, 3) * p0 +
          3 * Math.pow(u, 2) * t * p1 +
          3 * u * Math.pow(t, 2) * p2 +
          Math.pow(t, 3) * p3
        );
      };

      // Easing-out cubic Bézier care include un mic overshoot și apoi se stabilizează
      const easeOutCustom = (t) => {
        return cubicBezier(t, 0, 0.5, 0.9, 1);
      };

      const animateRotation = () => {
        const distanceToTarget = Math.abs(
          segmentsGroup.rotation.z - rotationTarget
        );

        // Distanța totală a rotației pentru normalizare
        const totalDistance = Math.abs(-10 * Math.PI - 2 * Math.PI); // Ajustează în funcție de rotația totală

        // Progresul animației (0 la 1) pe baza distanței curente
        const progress = Math.min(1, distanceToTarget / totalDistance);

        // Calculează viteza de rotație bazată pe progresul animației
        const easingProgress = easeOutCustom(progress);

        // Ajustează viteza pentru a fi minimă în apropierea finalului
        rotationSpeed = Math.max(0.005, easingProgress * 0.1);

        if (
          segmentsGroup.rotation.z > rotationTarget &&
          segmentsGroup.rotation.z - rotationSpeed > rotationTarget
        ) {
          segmentsGroup.rotation.z -= rotationSpeed;
          renderer.render(sceneCircle, camera);
          requestAnimationFrame(animateRotation);
        } else {
          // Asigură că roata ajunge exact la țintă
          segmentsGroup.rotation.z = rotationTarget;
          renderer.render(sceneCircle, camera);
          if (!isFinalMessageShow) {
            setTimeout(() => {
              showFinalMessage(prizeIndex);
            }, 100);
          }
        }
      };
      animateRotation();
    };

    resetWheelRef.current = () => {
      const startRotation = segmentsGroupRef.current.rotation.z;
      const targetRotation = 0;
      const duration = 1000; // Durata animației în milisecunde
      let startTime = null;

      const easeOutCubic = (t) => {
        return 1 - Math.pow(1 - t, 3);
      };

      const animate = (timestamp) => {
        if (!startTime) startTime = timestamp;
        const elapsed = timestamp - startTime;
        const progress = Math.min(elapsed / duration, 1); // Progresul animației (0 la 1)
        const easedProgress = easeOutCubic(progress);

        segmentsGroupRef.current.rotation.z =
          startRotation * (1 - easedProgress);

        if (progress < 1) {
          requestAnimationFrame(animate);
        } else {
          segmentsGroupRef.current.rotation.z = targetRotation; // Asigură că roata ajunge exact la 0
        }

        rendererRef.current.render(sceneCircle, camera); // Redesenare
      };

      requestAnimationFrame(animate);
    };

    const renderScene = () => {
      if (loadedImgs < numSegments) {
        requestAnimationFrame(renderScene);
      } else {
        buttonRef.current.addEventListener("click", handleWheelClick);
      }

      renderer.setClearColor(0x000000, 0);
      renderer.render(sceneCircle, camera);
    };

    renderScene();
  };

  const getPrize = async () => {
    const response = await fetch(
      "https://myplaceresidence.ro/wp-json/api/v1/campaign/get-prize"
    );
    if (response.ok) {
      const body = await response.json();

      return body.prize;
    }

    return "loser";
  };

  const handleWheelClick = async (e) => {
    e.preventDefault();
    setDataIsLoading(true);
    console.log("wheel click");
    const prize = await getPrize();
    // const prize = [
    //   "voucher",
    //   "loser",
    //   "vizionare-dezvoltator",
    //   "vizionare-constructor",
    //   "premiu-surpriza",
    // ][Math.floor(Math.random() * 5)];
    console.log("prize - ", prize);
    let prizeIndex;
    if (prize === "loser") {
      prizeIndex = 4;
    } else if (prize === "vizionare-dezvoltator") {
      prizeIndex = 3;
    } else if (prize === "vizionare-constructor") {
      prizeIndex = 2;
    } else if (prize === "premiu-surpriza") {
      prizeIndex = 1;
    }

    spinWheelRef.current(prizeIndex);
  };

  useEffect(() => {
    if (!isEffectRan) {
      setupWheel();
      buttonRef.current.addEventListener("click", handleWheelClick);
      setIsEffectRan(true);
    }
  }, [isEffectRan]);

  useEffect(() => {
    console.log("final message - ", isFinalMessageShow);
    if (isFinalMessageShow !== null) {
      let prizeName = "loser";
      if (isFinalMessageShow === 1) {
        prizeName = "premiu-surpriza";
      } else if (isFinalMessageShow === 2) {
        prizeName = "vizionare-constructor";
      } else if (isFinalMessageShow === 3) {
        prizeName = "vizionare-dezvoltator";
      } else if (isFinalMessageShow === 4) {
        prizeName = "loser";
      }

      setFormPrizeValues((prev) => ({
        ...prev,
        prize: prizeName,
      }));
      setTimeout(() => {
        setDataIsLoading(false);

        setIsFinalMessageShowActive(true);
      }, 500);
    }
  }, [isFinalMessageShow]);
  const handleTryAgain = (e) => {
    e.preventDefault();
    resetWheelRef.current();

    setFormPrizeValues({ email: "", prize: null });
    setIsFinalMessageShow(null);
    setIsFinalMessageShowActive(false);
    setShowFinalWindow(false);
  };
  return (
    <>
      <div ref={wheelContainerRef} id="wheel-container"></div>
      <button
        ref={buttonRef}
        className="campaign-button jersey-25-regular"
        disabled={dataIsLoading}
      >
        {dataIsLoading ? "Se încarcă" : "Participă"}
      </button>
      {isFinalMessageShow ? (
        <>
        <div className={`finalMessage jersey-25-regular ${isFinalMessageShowActive ? "active" : ""} `}>
            <h2>
                Felicitări
            </h2>
            <h3>
                Ai câștigat
            </h3>
            <h3>
                {formPrizeValues.prize}
            </h3>

            <button
                      onClick={handleTryAgain}
                      className="campaign-button-alt jersey-25-regular"
                    >
                      Rotire nouă
                    </button>
        </div>
        </>
      ) : <></>}
    </>
  );
}

export default WheelGame;
