import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function Login() {

    const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("register_token");
    const location = localStorage.getItem("register_location");

    if (token) {
      navigate(`/`);
    }
  }, []);


  const [formInputs, setFormInputs] = useState({
    pin: "",
  });
  const [error, setError] = useState(false);

  const handleFormInputChange = (e) => {
    const { name, value } = e.target;
    let filteredValue = value.replace(/[^0-9]/g, "");

    if (filteredValue.length < 5) {
      setFormInputs((prev) => ({
        ...prev,
        [name]: filteredValue,
      }));
    }
  };

  const handleLocationLogin = async (e) => {
    e.preventDefault();
    setError(false);

    const formData = new FormData();

    formData.append("pin", formInputs.pin);

    const response = await fetch("/api/v1/campaign/login", {
      method: "POST",
      body: formData,
    });
    if (response.ok) {
      const body = await response.json();

      console.log(body);

      if (body.status === "success") {
        localStorage.setItem("register_token", body.token);

        navigate(`/`);
      } else {
        setError(true);
      }
    }
  };


  return (
    <div className="location-login-page">
      <div className="location-login-container jersey-25-regular">
        <h2>Introdu codul PIN</h2>
        <form onSubmit={handleLocationLogin}>
          <div className="location-login-form-container">
            {error === true ? (
              <p className="location-login-error jersey-25-regular">Codul PIN este incorect</p>
            ) : (
              <></>
            )}
            <input
              type="password"
              name="pin"
              value={formInputs.pin}
              onChange={handleFormInputChange}
              autoComplete="off"
              inputMode="tel"
              required
            />
            <button className="jersey-25-regular">Autentificare</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Login